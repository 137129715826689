import { useQuery } from "@tanstack/react-query";
import { Collapse, theme } from "antd";
import { Link } from "react-router-dom";
import clientService from "../../../services/http-client";
import Footer from "../../footer";
import Header from "../../header";

const Faq = () => {
  const { token } = theme.useToken();
  const { data, isLoading } = useQuery({
    queryKey: ["faqFind"],
    queryFn: clientService.api.faqFind,
  });
  const panelStyle = {
    marginBottom: 10,
    marginTop: 10,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu={"faq"} />

        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Trang chủ</Link>
                      </li>
                      <li className="breadcrumb-item">FAQ</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="help-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="help-title">
                  <h3>Các câu hỏi thường xuyên</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <Collapse
                bordered={false}
                style={{ background: token.colorBgContainer }}
                items={data?.map((item) => {
                  return { key: item.id, label: item.tieuDe, children: item.noiDung, style: panelStyle };
                })}
              />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Faq;
