import { DatePicker } from "antd";
import dayjs from "dayjs";

// eslint-disable-next-line react/prop-types
export default function NgayThangNamSelect({ value, ...props }) {
  return (
    <DatePicker
      placeholder="DD/MM/YYYY"
      format="DD/MM/YYYY"
      {...props}
      style={{ width: "100%" }}
      value={typeof value === "string" ? dayjs(value) : value}
    />
  );
}
