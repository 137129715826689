import * as React from "react";
import ReactDOM from "react-dom/client";
// import { App } from "./app";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import "./assets/css/bootstrap.min.css";

//Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

//Font Awesome
import "./assets/plugins/fontawesome/css/all.min.css";
import "./assets/plugins/fontawesome/css/fontawesome.min.css";

//CSS
import "./assets/css/style.css";
import "./assets/css/quill.snow.css";

import Approuter from "./approuter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3000000, // 50 minutes
      //   cacheTime: 3600000, // 60 minutes,
      retry: 0,
    },
  },
});
root.render(
  <>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Approuter />
      </QueryClientProvider>
  </>
);
