import { MapPin } from "react-feather";
import { Link } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";
import clientService from "../../services/http-client";

const Footer = () => {
  const { data: danhMucDaoTao } = useQuery({
    queryKey: ["danhMucDaoTaoFind"],
    queryFn: () => clientService.api.danhMucDaoTaoFind(),
  });

  return (
    <footer className="footer footer-five">
      {/* Footer Top */}
      <div className="footer-top-five">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
              {/* Footer Widget */}
              <div className="footer-contact footer-menu-five">
                <h2 className="footer-title footer-title-five">Thông tin liên lạc</h2>
                <div className="footer-contact-info">
                  <div className="footer-address">
                    <span>
                      <MapPin size={16} />
                    </span>
                    <p>
                      Số 150 Phạm Hùng, Phường 5, Quận 8,
                      <br />
                      Thành phố Hồ Chí Minh
                    </p>
                  </div>
                  <p className="mb-0">
                    <span className="phone-icon">
                      <i className="fa-solid fa-phone-volume"></i>
                    </span>
                    Số điện thoại: 028.22 008 008 - 0969 789 789
                  </p>
                  <p className="mb-0">
                    <span className="mail-icon">
                      <i className="fa-solid fa-mail-bulk"></i>
                    </span>
                    info@icmp.edu.vn
                  </p>
                </div>
              </div>
              {/* Footer Widget */}
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              {/* Footer Widget */}
              <div className="footer-menu footer-menu-five">
                <h2 className="footer-title footer-title-five">
                  <i className="fa-sharp fa-solid fa-dash"></i>Đào tạo
                </h2>
                <ul>
                  <div className="row">
                    {danhMucDaoTao?.map((item, index) => {
                      return (
                        <li key={index} className="col-lg-6 col-md-6 col-sm-12">
                          <a href={`/course?filter=${item.id}`}>{item.ten}</a>
                        </li>
                      );
                    })}
                  </div>
                </ul>
              </div>
              {/* Footer Widget */}
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <h2 className="footer-title footer-title-five">Đăng ký thông tin</h2>
              {/* Footer Widget */}
              <div className="footer-widget-five">
                <div className="footer-news-five">
                  <form>
                    <div className="form-group mb-0">
                      <input type="text" className="form-control" placeholder="Vui lòng nhập email" />
                      <button type="submit" className="btn btn-one">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
                <div className="social-icon-five">
                  <ul className="nav">
                    <li>
                      <Link to="/" target="_blank" className="twitter-icon">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/" target="_blank" className="linked-icon">
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/" target="_blank" className="facebook-icon">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/" target="_blank" className="youtube-icon">
                        <i className="fab fa-youtube"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Footer Widget */}
            </div>
          </div>
        </div>
      </div>
      {/* Footer Top */}

      {/* Footer Bottom */}
      <div className="footer-bottom footer-bottom-five">
        <div className="container">
          {/* Copyright */}
          <div className="copyright-five">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="privacy-policy">
                  <ul>
                    <li>
                      <Link to="/chinh-sach">Chính sách - bảo mật</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="copyright-text">
                  <p>&copy; 2024 Viện Y Dược Lâm Sàng</p>
                </div>
              </div>
            </div>
          </div>
          {/* Copyright */}
        </div>
      </div>
      {/* Footer Bottom */}
      {/* Footer Bottom */}
    </footer>
  );
};
export default Footer;
