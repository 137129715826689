import React from "react";
import OwlCarousel from "react-owl-carousel";
import Lead1 from "../../../assets/img/doi-tac/1.jpg";
import Lead2 from "../../../assets/img/doi-tac/2.jpg";
import Lead3 from "../../../assets/img/doi-tac/3.jpg";
import Lead4 from "../../../assets/img/doi-tac/4.jpg";
import Lead5 from "../../../assets/img/doi-tac/5.jpg";
import Lead6 from "../../../assets/img/doi-tac/6.jpg";
const Companies = () => {
  var settings = {
    //autoWidth: true,
    items: 2,
    margin: 25,
    dots: false,
    nav: true,

    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 3,
      },
      1170: {
        items: 6,
      },
    },
  };
  return (
    <OwlCarousel
      {...settings}
      className="lead-group-slider owl-carousel owl-theme"
    >
      <div className="item">
        <div className="lead-img">
          <img className="img-fluid" alt="" src={Lead1} />
        </div>
      </div>
      <div className="item">
        <div className="lead-img">
          <img className="img-fluid" alt="" src={Lead2} />
        </div>
      </div>
      <div className="item">
        <div className="lead-img">
          <img className="img-fluid" alt="" src={Lead3} />
        </div>
      </div>
      <div className="item">
        <div className="lead-img">
          <img className="img-fluid" alt="" src={Lead4} />
        </div>
      </div>
      <div className="item">
        <div className="lead-img">
          <img className="img-fluid" alt="" src={Lead5} />
        </div>
      </div>
      <div className="item">
        <div className="lead-img">
          <img className="img-fluid" alt="" src={Lead6} />
        </div>
      </div>
    </OwlCarousel>
  );
};

export default Companies;
