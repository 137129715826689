import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Col, Form, Input, InputNumber, Modal, Row, Typography } from "antd";
import * as currencyFormatter from "currency-formatter";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../../components/header";
import cacheService from "../../../../services/cache-service";
import clientService from "../../../../services/http-client";
import NgayThangNamSelect from "../../../NgayThangNamSelect";
import Footer from "../../../footer";
import "./style.css";
const { Text } = Typography;
const CourseEnroll = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [giamGia, setGiamGia] = useState(null);
  const [maGiamGia, setMaGiamGia] = useState();
  const { data: dataKhoaHoc, isLoading } = useQuery({
    queryKey: ["khoaHocDetail", { id }],
    queryFn: () => clientService.api.khoaHocDetail(id),
    enabled: !!id,
  });

  const info = cacheService.getInfo();
  const { data: hocVien } = useQuery({
    queryKey: ["hocVienGetInfo"],
    queryFn: () => {
      if (!info.id) return;
      return clientService.api.hocVienGetInfo();
    },
  });
  const checkVoucherMutation = useMutation({
    mutationFn: (maVoucher) => clientService.api.voucherPublicGetVoucherByMa(maVoucher),
    onSuccess: (data) => {
      if (data) {
        setGiamGia(data);
      } else {
        setGiamGia(null);
      }
    },
  });
  useEffect(() => {
    if (!maGiamGia) return;
    const debounceTimer = setTimeout(() => {
      checkVoucherMutation.mutate(maGiamGia);
    }, 200);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [maGiamGia]);

  useEffect(() => {
    if (hocVien) {
      form.setFieldsValue({
        fullName: hocVien.user.fullName,
        phoneNumber: hocVien.user.phoneNumber,
        email: hocVien.user.email,
        birthdate: hocVien.user.birthdate,
        address: hocVien.user.address,
        donViCongTac: hocVien.donViCongTac,
        giaTien: dataKhoaHoc?.giaTien,
      });
    } else {
      form.setFieldsValue({
        giaTien: dataKhoaHoc?.giaTien,
      });
    }
  }, [hocVien, dataKhoaHoc]);

  const onFinish = async () => {
    const data = form.getFieldsValue();
    if (!hocVien)
      await clientService.api
        .hocVienEnrollCourseForNewHocVien({
          username: data.username,
          password: data.password,
          fullName: data.fullName,
          birthdate: data.birthdate,
          phoneNumber: data.phoneNumber,
          email: data.email,
          address: data.address,
          donViCongTac: data.donViCongTac,
          maVoucher: data.maVoucher,
          khoaHoc: dataKhoaHoc,
        })
        .then((res) => {
          if (res) {
            Modal.success({
              title: "Đăng ký khóa học thành công",
              content: "Bạn có thể xem thông tin khóa học tại trang cá nhân",
              onOk() {
                window.location.href = `/course-details/${id}`;
              },
            });
          }
        })
        .catch((err) => {
          // console.log(err)
          form.setFields(err.errors);
        });
    else {
      await clientService.api
        .hocVienEnrollCourseForOldHocVien({
          maVoucher: data.maVoucher,
          khoaHocId: dataKhoaHoc.id,
        })
        .then((res) => {
          if (res) {
            Modal.success({
              title: "Đăng ký khóa học thành công",
              content: "Bạn có thể xem thông tin khóa học tại trang cá nhân",
              onOk() {
                window.location.href = `/course-details/${id}`;
              },
            });
          }
        });
    }
  };

  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu={"course"} />

        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">Đăng ký ghi danh khóa học</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Trang chủ</a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Đăng ký khóa học
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-12">
            <div className="card-body">
              <h5 className="title-header">Thông tin khóa học</h5>
              <did className="info-course">
                <div className="row-info">
                  <span className="label">Tên khóa học</span>
                  <span className="colon"> :</span>
                  <span className="value">
                    <h6>{dataKhoaHoc?.tenKhoaHoc}</h6>
                  </span>
                </div>
                <div className="row-info">
                  <span className="label">Thời gian</span>
                  <span className="colon"> :</span>
                  <span className="value">
                    <h6>{dataKhoaHoc?.ngayKhaiGiang ? dayjs(dataKhoaHoc?.ngayKhaiGiang).format("DD/MM/YYYY") : "Chưa có"}</h6>
                  </span>
                </div>
                <div className="row-info">
                  <span className="label">Loại hình</span>
                  <span className="colon"> :</span>
                  <span className="value">
                    <h6>{dataKhoaHoc?.loaiChungChi?.ten}</h6>
                  </span>
                </div>
                <div className="row-info">
                  <span className="label">Địa chỉ</span>
                  <span className="colon"> :</span>
                  <span className="value">
                    <h6>{dataKhoaHoc?.diaChi}</h6>
                  </span>
                </div>
              </did>
              <h5 className="title-header">Thông tin học viên</h5>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={20}>
                  <Col lg={12} xs={24}>
                    <Form.Item name={"email"} rules={[{ required: true }]} label="Email">
                      <Input disabled={!!hocVien} />
                    </Form.Item>
                  </Col>
                  {!hocVien && (
                    <Col lg={6} xs={24}>
                      <Form.Item name={"password"} rules={[{ required: true }]} label="Mật khẩu">
                        <Input.Password />
                      </Form.Item>
                    </Col>
                  )}
                  {!hocVien && (
                    <Col lg={6} xs={24}>
                      <Form.Item
                        name={"confirm_password"}
                        rules={[
                          { required: true, message: "Nhập lại mật khẩu" },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue("password") === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error("Mật khẩu không trùng"));
                            },
                          }),
                        ]}
                        label="Nhập lại mật khẩu"
                      >
                        <Input.Password />
                      </Form.Item>
                    </Col>
                  )}
                  <Col lg={12} xs={24}>
                    <Form.Item name={"fullName"} rules={[{ required: true }]} label="Tên học viên">
                      <Input disabled={hocVien?.user?.fullName} />
                    </Form.Item>
                  </Col>

                  <Col lg={6} xs={24}>
                    <Form.Item name="birthdate" rules={[{ required: true, message: "Ngày sinh bắt buộc nhập" }]} label="Ngày sinh">
                      <NgayThangNamSelect disabled={hocVien?.user?.birthdate} />
                    </Form.Item>
                  </Col>
                  <Col lg={6} xs={24}>
                    <Form.Item name={"phoneNumber"} rules={[{ required: true }]} label="Điện thoại">
                      <Input disabled={hocVien?.user?.phoneNumber} />
                    </Form.Item>
                  </Col>

                  <Col lg={12} xs={24}>
                    <Form.Item name={"address"} label="Địa chỉ">
                      <Input disabled={hocVien?.user?.diaChi} />
                    </Form.Item>
                  </Col>
                  <Col lg={12} xs={24}>
                    <Form.Item name={"donViCongTac"} label="Địa chỉ công tác">
                      <Input disabled={hocVien?.user?.donViCongTac} />
                    </Form.Item>
                  </Col>
                  <Col lg={12} xs={24}>
                    <Form.Item name={"giaTien"} label="Giá tiền khóa học">
                      <InputNumber
                        style={{ width: "100%" }}
                        suffix="đ"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        parser={(value) => value.replace(/\D/g, "")}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} xs={24}>
                    <Form.Item name={"maVoucher"} label="Mã giảm giá">
                      <Input onChange={(e) => setMaGiamGia(e.target.value)} />
                    </Form.Item>
                  </Col>
                  <Col lg={6} xs={24}>
                    <Form.Item label=" ">
                      {giamGia && (
                        <Text style={{ color: "red" }}>
                          Bạn được giảm{" "}
                          {giamGia.loaiGiam === "GiaTri"
                            ? currencyFormatter.format(giamGia.giam, {
                                code: "VND",
                              })
                            : `${giamGia.giam}%`}{" "}
                          khi áp dụng mã này
                        </Text>
                      )}
                    </Form.Item>
                  </Col>
                  {giamGia && (
                    <Col lg={12} xs={24}>
                      <Form.Item label=" ">
                        <span>
                          Giá tiền khóa học còn lại:{" "}
                          <Text style={{ color: "red" }}>
                            {currencyFormatter.format(
                              giamGia.loaiGiam === "GiaTri"
                                ? form.getFieldValue("giaTien") - giamGia.giam
                                : form.getFieldValue("giaTien") - (form.getFieldValue("giaTien") * giamGia.giam) / 100,
                              {
                                code: "VND",
                              }
                            )}
                          </Text>
                        </span>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                <Button className="button" htmlType="submit">
                  Đăng ký khóa học
                </Button>
              </Form>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default CourseEnroll;
