// import Collapse from 'react-bootstrap/Collapse';
import { EyeOutlined, FileTextOutlined, LockOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Button, Col, Row } from "antd";
import * as currencyFormatter from "currency-formatter";
import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import cacheService from "../../../../services/cache-service";
import clientService from "../../../../services/http-client";
import { Chapter, Timer2, Users } from "../../../imagepath";
const DetailsContent = () => {
  const { id, docId } = useParams();
  const info = cacheService.getInfo();

  const { data, isLoading } = useQuery({
    queryKey: ["khoaHocDetail", { id }],
    queryFn: () => clientService.api.khoaHocDetail(id),
    enabled: !!id,
  });

  const { data: taiLieu } = useQuery({
    queryKey: ["taiLieuDetail", { docId }],
    queryFn: () => clientService.api.taiLieuHocTapDetail(docId),
    enabled: !!docId,
  });

  const { data: quangCao } = useQuery({
    queryKey: ["quangCaoFind", { viTri: 2 }],
    queryFn: () =>
      clientService.api.quangCaoFind({
        query: {
          where: {
            viTri: 2,
          },
        },
      }),
  });

  const { data: dangKyKhoaHoc } = useQuery({
    queryKey: ["khoaHocCheckHocVienGhiDanh", { id, hocVienId: info?.hocVienId ?? 0 }],
    queryFn: () => {
      if (!info.hocVienId) return;
      return clientService.api.khoaHocCheckHocVienGhiDanh(id, info.hocVienId);
    },
  });
  return (
    <>
      <section className="page-content course-sec">
        <div className="container">
          <div className="row">
            {docId ? (
              <div className="col-lg-8">
                {/* Overview */}
                <div className="card overview-sec">
                  <div className="card-body">
                    <Row>
                      <Col span={20}>
                        <h5 className="subs-title">Tài liệu khóa học</h5>
                      </Col>
                      <Col span={4}>
                        <Link to={`/course-details/${id}`}>
                          <Button type="primary">Quay lại</Button>
                        </Link>
                      </Col>
                    </Row>
                    <div className="cou-title" style={{ marginBottom: 5 }}>
                      {taiLieu?.dinhKem && (
                        <a href={`${taiLieu?.dinhKem?.path}`} target="_blank">
                          Xem hoặc tải file đính kèm: {taiLieu?.dinhKem?.name}
                        </a>
                      )}
                      {taiLieu?.linkVideo && (
                        <a href={`${taiLieu?.linkVideo}`} target="_blank">
                          {taiLieu?.linkVideo}
                        </a>
                      )}
                    </div>

                    <div>
                      <div dangerouslySetInnerHTML={{ __html: taiLieu?.moTa }} />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-lg-8">
                {/* Overview */}
                <div className="card overview-sec">
                  <div className="card-body">
                    <h5 className="subs-title">Mô tả khóa học</h5>
                    <div>
                      <div dangerouslySetInnerHTML={{ __html: data?.mota }} />
                    </div>
                  </div>
                </div>
                {/* /Overview */}
                <div className="card content-sec">
                  <div className="card-body">
                    <div className="course-card">
                      <h6 className="cou-title subs-title">Tài liệu khóa học</h6>
                      <div className="card-collapse collapse show">
                        <ul>
                          {data?.taiLieuHocTaps
                            .filter((item) => item.loaiTaiLieu == "TaiLieu")
                            .map((item) => (
                              <li>
                                <p>
                                  <FileTextOutlined /> {item.tenTaiLieu}
                                </p>
                                <div>
                                  {item.hienThi ? (
                                    <Link to={`/course-details/${id}/doc/${item.id}`}>
                                      <Button icon={<EyeOutlined />} width={30} type="primary">
                                        Xem
                                      </Button>
                                    </Link>
                                  ) : (
                                    <Button icon={<LockOutlined />} disabled>
                                      Đăng ký
                                    </Button>
                                  )}
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="course-card">
                      <h6 className="cou-title subs-title">Video/ Live stream bài giảng</h6>
                      <div className="card-collapse collapse show">
                        <ul>
                          {data?.taiLieuHocTaps
                            .filter((item) => item.loaiTaiLieu == "Video")
                            .map((item) => (
                              <li>
                                <p>
                                  <VideoCameraOutlined /> {item.tenVideo}
                                </p>
                                <div>
                                  {item.hienThi ? (
                                    <Link to={`/course-details/${id}/doc/${item.id}`}>
                                      <Button icon={<EyeOutlined />} width={30} type="primary">
                                        Xem
                                      </Button>
                                    </Link>
                                  ) : (
                                    <Button icon={<LockOutlined />} disabled>
                                      Đăng ký
                                    </Button>
                                  )}
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-lg-4">
              <div className="sidebar-sec">
                {/* Video */}
                <div className="video-sec vid-bg">
                  <div className="card">
                    <div className="card-body">
                      <div className="video-details">
                        {dangKyKhoaHoc && dangKyKhoaHoc.id ? (
                          <span className="btn btn-black w-100 disabled">Bạn đã ghi danh khóa học</span>
                        ) : dayjs().isAfter(data?.ngayKetThuc) ? (
                          <span className="btn btn-black w-100 disabled">Khóa học đã kết thúc</span>
                        ) : (
                          <Link to={data?.id && `/course-enroll/${data.id}`} className="btn btn-enroll w-100">
                            {!!data?.ngayKhaiGiang ? "Ghi danh khóa học" : "Đăng ký giữ chỗ"}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Video */}
                {/* Features */}
                <div className="card feature-sec">
                  <div className="card-body">
                    <div className="cat-title">
                      <h4>Khóa học bao gồm</h4>
                    </div>
                    <ul>
                      <li>
                        <img src={Users} className="me-2" alt="" />
                        Số lượng học viên: <span>{data?.soLuongHocVien}</span>
                      </li>
                      <li>
                        <img src={Timer2} className="me-2" alt="" />
                        Số ngày học: <span>{data?.soNgayHoc}</span>
                      </li>
                      <li>
                        <img src={Chapter} className="me-2" alt="" />
                        Số tiết học: <span>{data?.soTiet}</span>
                      </li>
                      {/* <li>
                        <img src={Video2} className="me-2" alt="" />Buổi học online:
                        <span> 12 hours</span>
                      </li>
                      <li>
                        <img src={Chart} className="me-2" alt="" />Tài liệu học tập: <span>Beginner</span>
                      </li> */}
                    </ul>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    {quangCao?.map(({ hinhAnh, link }) => (
                      <a href={`${link}`} target="_blank">
                        <img src={`${hinhAnh.path}`} width={"410"} style={{ marginBottom: 10 }} />
                      </a>
                    ))}
                  </div>
                </div>
                {/* /Features */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DetailsContent;
